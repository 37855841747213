<template>
  <div id="live-kifus" class="relative p-md pb-md pt-0 h-full overflow-scroll">
    <div v-show="liveKifus.length > 0">
      <div class="text-18 text-f-black mt-md mb-md">直播中</div>
      <Card
        v-for="kifu in liveKifus"
        class="mt-lg"
        :key="kifu.id"
        :kifu="kifu"
        @click="goToKifu(kifu.id)"
      ></Card>
    </div>
    <div v-show="notLiveKifus.length > 0">
      <div class="text-18 text-f-black mt-md mb-md">已結束的直播</div>
      <Card
        v-for="kifu in notLiveKifus"
        class="mt-lg"
        :key="kifu.id"
        :kifu="kifu"
        @click="goToKifu(kifu.id)"
      ></Card>
    </div>
    <Empty
      v-if="!$apollo.loading"
      v-show="liveKifus.length == 0 && notLiveKifus.length == 0"
      class="h-full"
      text="目前沒有此分類的棋譜"
    ></Empty>

    <KifuFormPublic
      v-if="isKifuFormShow"
      class="absolute top-0 left-0"
      mode="edit"
      :isStream="true"
    ></KifuFormPublic>
  </div>
</template>

<script>
import dayjs from 'dayjs';
import loadingMixin from '@/mixins/loadingMixin';
import Card from '@/components/Kifu/Card';
import Empty from '@/components/Common/Empty';
import KifuFormPublic from '@/components/Kifu/KifuFormPublic';
import GET_PUBLIC_KIFUS from '@/graphql/queries/GetPublicKifus.gql';
import GET_USER from '@/graphql/queries/GetUser.gql';
import UPDATE_HEADER_CONFIG from '@/graphql/mutations/UpdateHeaderConfig.gql';

export default {
  name: 'LiveKifus',
  components: {
    Card,
    Empty,
    KifuFormPublic,
  },
  mixins: [loadingMixin],
  apollo: {
    user: {
      query: GET_USER,
    },
    liveKifus: {
      query: GET_PUBLIC_KIFUS,
      update: (data) => data?.publicKifus?.kifus || [],
      variables() {
        const filter = {
          limit: 20,
          page: 1,
          isStreaming: true,
          orderBy: [
            {
              column: 'gameStartedAt',
              reverse: true,
            },
          ],
        };
        return {
          filter,
          isLogin: !!this.user,
        };
      },
    },
    notLiveKifus: {
      query: GET_PUBLIC_KIFUS,
      update: (data) => data?.publicKifus?.kifus || [],
      variables() {
        const filter = {
          limit: 20,
          page: 1,
          source: 'STREAM',
          isStreaming: false,
          gameStartedAfter: dayjs().startOf('day'),
        };

        return {
          filter,
          isLogin: !!this.user,
        };
      },
    },
  },
  data() {
    return {
      isKifuFormShow: false,
      liveKifus: [],
      notLiveKifus: [],
    };
  },
  computed: {
    permissions() {
      return this.user?.role?.permissions || [];
    },
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.$watch(
        'permissions',
        () => {
          vm.initialHeaderConfig();
        },
        {immediate: true},
      );
    });
  },
  beforeDestroy() {
    this.$bus.$off('header:next');
  },
  methods: {
    goToKifu(id) {
      this.$router.push({name: 'kifu', params: {id}});
    },
    initialHeaderConfig() {
      const headerConfig = this.permissions.some(
        (permission) => permission.code == 'STREAM',
      )
        ? {
            title: '直播',
            left: 'back',
            middle: 'title',
            right: 'next',
            nextText: '建立直播',
          }
        : {
            title: '直播',
            left: 'back',
            middle: 'title',
          };
      this.$apollo.mutate({
        mutation: UPDATE_HEADER_CONFIG,
        variables: {
          headerConfig,
        },
      });

      this.$bus.$off('header:next');
      this.$bus.$on('header:next', () => {
        if (!this.isKifuFormShow) {
          this.isKifuFormShow = true;
          this.$apollo.mutate({
            mutation: UPDATE_HEADER_CONFIG,
            variables: {
              headerConfig: {
                nextText: '確定',
                nextDisabled: true,
              },
              update: true,
            },
          });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
#live-kifus {
}
</style>
